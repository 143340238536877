import { Page, TableRow, Table, TableData } from './Page.styles';

const Page2 = ({ data, productName }) => {
  return (
    <Page>
      <h3>Försäkrade djur på detta avtal</h3>
      Försäkringsinformation för {data.answers.petName}
      <Table>
        <tbody>
          <TableRow>
            <TableData>Försäkringsnummer</TableData>
            <TableData>{data.policyNumber}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Djur</TableData>
            <TableData>{productName}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Kön</TableData>
            <TableData>{data.answers.sex}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Födelsedatum</TableData>
            <TableData>{data.answers.petDateOfBirth}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Ras</TableData>
            <TableData>
              {data.answers.breed}{' '}
              {data.coverLevel === 'Blandras' &&
                data.answers.breed !== data.answers.mixedBreed &&
                `/ ${data.answers.mixedBreed}`}{' '}
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Djurägarerfarenhet</TableData>
            <TableData>{data.answers.ownerExperience}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Primär användning</TableData>
            <TableData>{data.answers.petUse}</TableData>
          </TableRow>
          <TableRow>
            <TableData>ID-nummer</TableData>
            <TableData>
              {data.answers.chipNumber
                .trim()
                .replace(' ', '')
                .split('')
                .every((char) => char === '0')
                ? 'Ej angivet'
                : data.answers.chipNumber}
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Försäkringsbelopp</TableData>
            <TableData>
              {Number(data.answers.sumInsured).toLocaleString('sv-SE')} SEK
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Fast självrisk</TableData>
            <TableData>
              {Number(data.answers.fixedExcess).toLocaleString('sv-SE')} SEK
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Rörlig självrisk</TableData>
            <TableData>{data.answers.propExcess}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Tillägg</TableData>
            <TableData>
              {data.answers.optLife === 'true' && (
                <span>
                  Liv (
                  {Number(data.answers.lifeSumInsured).toLocaleString('sv-SE')})
                  <br />
                </span>
              )}
              {data.answers.optUtility === 'true' && (
                <span>
                  Liv med användbarhetsförsäkring
                  <br />
                </span>
              )}
              {data.answers.optBreeding === 'true' && (
                <span>
                  Avel <br />
                </span>
              )}

              {data.answers.optLife !== 'true' &&
                data.answers.optBreeding !== 'true' &&
                data.answers.optUtility !== 'true' &&
                '-'}
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>15% lanseringsrabatt</TableData>
            <TableData>{data.answers.foodCustomer}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Rabatt</TableData>
            <TableData>
              {data.answers.youngPetDiscount
                ? data.answers.youngPetDiscount
                : '-'}
            </TableData>
          </TableRow>
        </tbody>
      </Table>
      {(data.answers.currentlyHealthy === 'Nej' ||
        data.answers.hasMedicalHistoryLastTwelveMonth === 'Ja') && (
        <p style={{ marginTop: '3rem' }}>
          Du har angivit att ditt djur uppsökt veterinär någon gång under de
          senaste 12 månaderna. Detta kan innebära att det påförs reservation i
          din försäkring. Kontakta gärna oss vid frågor.
        </p>
      )}
    </Page>
  );
};

export default Page2;
